<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Contact" icon="user-circle" />

    <div class="mainBoxBorderless mainBox55">
        <div class="row">
            <div class="col-12 col-lg-8 pb-1">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Name / Email">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-4 text-end text-primary isLink pt-2">
                <span data-bs-toggle="modal" data-bs-target="#mdlAddUser" class="isLink">
                    <fa icon="user-circle" size="lg" />
                    <span class="me-2 fw-bold" style="font-size: 14px">+</span>Add contact
                </span>
            </div>
           
        </div>
    </div>

    <div class="nextBox nextBox55 border shadow-sm">
        <div class="row">
            <div class="col-12 px-1" ref="scrollComponent">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>
                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
 
                <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                    <span class="mx-2 text-grey isLink"><fa icon="trash" size="lg" style="padding-left: 9px" /></span>
                    <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                </div>

                <table class="table table-hover" border="0">
                    <tbody>
                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                            
                            <td width="50px" class="text-center">
                                <!-- Large screen -->
                                <div class="d-none d-md-block">
                                    <div v-if="checkedUser.includes(itemIdx)" style="font-size: 16px; margin-left: -5px; padding-top: 6px; padding-bottom: 6px">
                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                    </div>
                                    <div v-else>
                                        <span class="hoverHide" :id="'avatar' + itemIdx">
                                            <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" width="30" height="30" />
                                        </span>
                                        <span class="hoverShow" style="z-index:1000; font-size: 15px; margin-top: -30px; margin-left: -10px; position:absolute;">
                                            <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                        </span>
                                    </div>
                                </div>
                                <!-- End Large screen -->

                                <!-- Small screen -->
                                <div class="d-block d-md-none">
                                    <span class="hoverHide" :id="'avatar' + itemIdx">
                                        <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" width="30" height="30" />
                                    </span>
                                </div>
                                <!-- End Small screen -->
                            </td>
                           
                            <td class="align-middle">
                                <div class="row">
                                    <div class="col-lg-5 fw-bold">
                                        <div class="isLink">
                                            <router-link :to="'/contact/' + item.userId">
                                                <span>{{item.lastName}} {{item.firstName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ item.nickname }}</span>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 small text-grey">{{ item.userId }}</div>
                                    <div class="col-lg-2 text-end d-none d-md-block hoverShow">
                                        <span class="text-grey isLink me-2">
                                            <Popper class="popperDark" arrow hover content="Delete">
                                                <fa icon="trash" size="lg" />
                                            </Popper>
                                        </span>
                                    </div>
                                    <!-- Small screen -->
                                    <div class="col-12 text-end d-block d-md-none">
                                        <span class="text-grey isLink me-4">
                                            <fa icon="trash" size="lg" />
                                        </span>
                                        <span>
                                            <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                        </span>
                                    </div>
                                    <!-- End Small screen -->
                                </div>
                            </td>
                        </tr>

                        <tr v-if="isLoadingMore" class="mt-3 text-center">
                            <td colSpan="2">
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    
    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddUser"
        tabindex="-1"
        aria-labelledby="AddUser"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark modal-sm">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title"> Add Contact</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-3">
                            <input v-model="firstName" class="form-control" placeholder="First name">
                        </div>

                        <div class="col-12 pb-3">
                            <input v-model="lastName" class="form-control" placeholder="Last name">
                        </div>

                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 pb-3">
                            <input v-model="nickname" class="form-control" placeholder="Nickname">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'ContactList',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])

        const filterName = ref('')
        const email = ref(null)
        const firstName = ref(null)
        const lastName = ref(null)
        const nickname = ref(null)
        const title = ref('Mr')
        const gender = ref('M')
        const userPackage = ref('FREE')
        const gmt = ref('+8:00')
        const language = ref('EN')
        const currency = ref('MYR')
        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        watch([checkedUser], () => {
            console.info('watch', JSON.stringify(checkedUser.value))
        })

        const getGmt = () => {
            // console.info('gmt', new Date())
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getTotal = () => {

            total.value = 8
            /* axios.post('/report/dash/signon/signed', {})
            .then((res) => {

                if (res.data.status === 1) {
                    total.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getTotal | ERR', JSON.stringify(error))
            }) */


        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    nickname: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development']
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    nickname: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs']
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    nickname: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: []
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    nickname: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis']
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    nickname: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi']
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: '',
                    lastName: '',
                    nickname: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management']
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    nickname: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support']
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    nickname: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development']
                },

            ]
            /* const p = {
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'desc'
                    }
                ],
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            console.info('getSig params', JSON.stringify(p))
            axios.post( '/report/dash/signon/signed', p
            ).then((res) => {

                if (loadMore) {
                    console.info('load more here')
                    isLoadingMore.value = false

                } else {
                    console.info('x load more here')
                    isLoading.value = false
                }

                if (res.data.status === 1) {
                   if (res.data.data.length > 0) {
                        arrItem.value.push(...res.data.data)
                    }
                } 
            })
            .catch((error) => {
                console.log('getList ERR', JSON.stringify(error))
            }) */
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const delfilterName = () => {
            arrItem.value = []
            filterName.value = ''
            getList(null)
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)

            rcdFr.value = 0
            
            getGmt()
            getTotal()
            getList(false)

            // Title list
            axios.get('/reference/title')
            .then((res) => {
                if (res.data.status === 1) {
                    titleOpt.value = res.data.data
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, router, func, store, scrollComponent, 
            total, clsAvatar, clsCheckbox, checkedUser, 
            arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore, filterName, 
            email, firstName, lastName, nickname, title, gender, userPackage, 
            gmt, language, currency, gmtOpt, titleOpt, genderOpt, delfilterName,  
        }
    }
}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>